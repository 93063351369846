import { createGlobalState } from "react-hooks-global-state";

const initialState = {
  username: "",
  user: {},
  user_id: null,
  macAddress: "",
  app: "",
  message: "",
  token: null,
  payment: false,
};

const { setGlobalState, useGlobalState } = createGlobalState(initialState);

export { setGlobalState, useGlobalState };
