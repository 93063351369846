import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "bootstrap/dist/css/bootstrap.css";
import Carousel from "react-bootstrap/Carousel";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";

import { LOCAL_URL } from "../Constants";

export default function Hero() {
  const [email, setEmail] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleSendEmail = () => {
    if (!email || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setErrorMessage(`${email} is invalid email address`);
      setOpen(true);
      return;
    }
    console.log("Email: ", email);
    const token = localStorage.getItem("token");
    try {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .post(`${LOCAL_URL}/api/email/send_inquiry_email/${email}`)
        .then((response) => {
          console.log("Logout successful:", response.data);
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : `linear-gradient(#02294F, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundSize: "",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 4, sm: 4 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
              fontSize: "clamp(3.5rem, 10vw, 4rem)",
            }}
          >
            Our latest&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: "clamp(3rem, 10vw, 4rem)",
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "primary.light",
              }}
            >
              Quest SQL AI
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
          >
            Quest SQL AI is a desktop IDE solution meticulously crafted to
            elevate your Oracle Cloud journey. Tailored specifically for Oracle
            Fusion ERP/SCM/HCM Pods, it offers a seamless and efficient
            interface, ensuring that accessing your cloud databases is both
            effortless and productive.
          </Typography>
          {/* <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              inputProps={{
                autocomplete: "off",
                ariaLabel: "Enter your email address",
              }}
            />
            <Button variant="contained" color="primary">
              Start now
            </Button>
          </Stack>
          <Typography
            variant="caption"
            textAlign="center"
            sx={{ opacity: 0.8 }}
          >
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            onClose={handleClose}
            message={errorMessage}
          />
          <Stack direction="row" spacing={1} useFlexGap>
            <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              email={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              aria-label="Enter your email address"
              placeholder="Your email address"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendEmail}
              sx={{ flexShrink: 0 }}
            >
              Get More Info
            </Button>
          </Stack>
        </Stack>
        {/* <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            height: { xs: 200, sm: 700 },
            width: "100%",
            backgroundImage:
              theme.palette.mode === "light"
                ? 'url("assets/ide_1.png")'
                : 'url("assets/ide_1.png")',
            backgroundSize: "cover",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#9CCCFC", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#9CCCFC", 0.2)}`
                : `0 0 24px 12px ${alpha("#033363", 0.2)}`,
          })}
        /> */}
        <div
          style={{
            display: "block",
            width: "100%",
            padding: 20,
            backgroundColor: "lavender",
            marginTop: 30,
          }}
        >
          <Carousel>
            <Carousel.Item interval={2000}>
              <Box
                id="image"
                sx={(theme) => ({
                  alignSelf: "center",
                  justifyContent: "center",
                  height: { xs: 200, sm: 600 },
                  width: "100%",
                  backgroundImage: 'url("assets/ide_1.png")',
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                })}
              />
              <Carousel.Caption>
                <h3>Login Screen</h3>
                <p>Login using cloud credentials</p>
              </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item interval={2000}>
              <Box
                id="image"
                sx={(theme) => ({
                  alignSelf: "center",
                  height: { xs: 200, sm: 600 },
                  width: "100%",
                  backgroundImage: 'url("assets/ide_2.png")',
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                })}
              />
              <Carousel.Caption>
                <h3>Dashboard</h3>
                <p>Dashboard and Charts</p>
              </Carousel.Caption>
            </Carousel.Item> */}

            <Carousel.Item interval={2000}>
              <Box
                id="image"
                sx={(theme) => ({
                  alignSelf: "center",
                  height: { xs: 200, sm: 600 },
                  width: "100%",
                  backgroundImage: 'url("assets/ide_3.png")',
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                })}
              />
              <Carousel.Caption>
                <h3>SQL Editor</h3>
                <p>Query editors</p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={2000}>
              <Box
                id="image"
                sx={(theme) => ({
                  alignSelf: "center",
                  height: { xs: 200, sm: 600 },
                  width: "100%",
                  backgroundImage: 'url("assets/ide_3_2.png")',
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                })}
              />
              <Carousel.Caption>
                <h3>SQL Editor</h3>
                <p>Query editors</p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item interval={2000}>
              <Box
                id="image"
                sx={(theme) => ({
                  alignSelf: "center",
                  height: { xs: 200, sm: 600 },
                  width: "100%",
                  backgroundImage: 'url("assets/ide_4.png")',
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                })}
              />
              <Carousel.Caption>
                <h3>Connections</h3>
                <p>Cloud Connections</p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
    </Box>
  );
}
