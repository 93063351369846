import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOCAL_URL } from "./Constants";

const Logout = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    try {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios
        .post(LOCAL_URL + "/api/auth/logout")
        .then((response) => {
          console.log("Logout successful:", response.data);
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    } catch (error) {
      console.error("Logout failed:", error);
    }
    localStorage.removeItem("token");
    navigate("/home");
  }, []);

  return (
    <div>
      <h1>Logout</h1>
    </div>
  );
};

export default Logout;
