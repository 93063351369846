import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOCAL_URL } from "./Constants";
import { Grid } from "@mui/material";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

const Logout = (props) => {
  const navigate = useNavigate();
  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [5, 10, 20, 100];
  const [rowData, setRowData] = useState([{ Columns: "Empty rows ..." }]);

  // Column Definitions: Defines the columns to be displayed.
  const [columnDefs, setColumnDefs] = useState([{ field: "Columns", flex: 1 }]);

  const handleUnauthorized = () => {
    console.log("Handling unuthorized");
    navigate("/login");
  };

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  if (!token || !user_id) {
    handleUnauthorized();
  }

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  useEffect(() => {
    try {
      axios.get(LOCAL_URL + `/api/query`, config).then((response) => {
        console.log("Success:", response.data[0]);
        let newCols = [
          "id",
          "description",
          "tags",
          "query",
          "query_time",
          "query_result_count",
          "is_active",
          "created_at",
          "updated_at",
        ].map((key) => {
          return { field: key, flex: 1, filter: true };
        });
        console.log("New Columns:", newCols);
        setColumnDefs(newCols);
        setRowData(response.data);
      });
    } catch (error) {
      console.error("Logout failed:", error.response.data.error);
    }
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <div
            className="ag-theme-quartz" // applying the grid theme
            style={{ height: 500 }} // the grid will fill the size of the parent container
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              rowSelection="multiple"
              suppressRowClickSelection={true}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Logout;
