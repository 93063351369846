import React from 'react';
import Typography from '@mui/material/Typography';

const NoPage = () => {
    return (
        <div>
            <Typography variant="h1">404 Page Not Found</Typography>
            <Typography variant="body1">The page you are looking for does not exist.</Typography>
        </div>
    );
};

export default NoPage;
