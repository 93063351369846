import * as React from "react";
import { LOCAL_URL } from "./Constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

export default function Profile() {
  const navigate = useNavigate();
  const handleUnauthorized = () => {
    console.log("Handling unuthorized");
    navigate("/login");
  };

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  if (!token || !user_id) {
    console.log("Error: Unauthorized");
    // handleUnauthorized();
  }

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const pagination = true;
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [5, 10, 20, 100];
  const [rowData, setRowData] = useState([{ Columns: "Empty rows ..." }]);

  const [columnDefs, setColumnDefs] = useState([{ field: "Columns", flex: 1 }]);
  const username = localStorage.getItem("username");
  const macAddress = localStorage.getItem("macAddress");
  const message = localStorage.getItem("message");
  const payment = localStorage.getItem("payment");

  React.useEffect(() => {
    axios
      .get(`${LOCAL_URL}/api/user/login/10`, config)
      .then((response) => {
        if ([400, 401, 403, 404].includes(response.status)) {
          handleUnauthorized();
        }
        console.log("Success:", response.data[0]);
        let newCols = ["login_time", "logout_time", "mac_address"].map(
          (key) => {
            if (key.includes("time")) {
              return {
                field: key,
                flex: 2,
                valueFormatter: ({ value }) => new Date(value).toLocaleString(),
              };
            }
            if (key === "mac_address") {
              return {
                field: key,
                flex: 2,
                filter: true,
                valueFormatter: ({ value }) => {
                  return value === "00:00:00:00:00:00"
                    ? "Cloud Login"
                    : `Desktop Login - ${value}`;
                },
              };
            }
            return { field: key, flex: 1, filter: true };
          }
        );
        console.log("New Columns:", newCols);
        setColumnDefs(newCols);
        setRowData(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        if ([400, 401, 403, 404].includes(error.response.status)) {
          handleUnauthorized();
        }
        // handleUnauthorized();
      });
  }, []);

  const downloadTxtFile = () => {
    axios.get(`${LOCAL_URL}/api/software`, config).then((response) => {
      console.log("Success:", response.data);
      const element = document.createElement("a");
      const file = new Blob([response.data], {
        type: "text/zip",
      });
      element.href = URL.createObjectURL(file);
      element.download = "QuestSQLAI.zip";
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Alert severity="warning" style={{ paddingBottom: "5px" }}>
          {message}
        </Alert>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant="h4">Profile</Typography>
            <Typography variant="h6">
              Username: <b>{username}</b>
            </Typography>
            {macAddress && (
              <Typography variant="h6">
                Desktop MAC: <b>{macAddress}</b>
              </Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              id="downloadBtn"
              variant="contained"
              endIcon={<DownloadIcon />}
              onClick={downloadTxtFile}
              value="download"
              height="100px"
              disabled={payment === "false"}
            >
              Download Quest SQL AI Application
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8}>
        <div
          className="ag-theme-quartz" // applying the grid theme
          style={{ height: 500 }} // the grid will fill the size of the parent container
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            pagination={pagination}
            paginationPageSize={paginationPageSize}
            paginationPageSizeSelector={paginationPageSizeSelector}
            rowSelection="multiple"
            suppressRowClickSelection={true}
          />
        </div>
      </Grid>
    </Grid>
  );
}
